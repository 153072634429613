<template>
  <div class="cartype">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="转运车名称">
            <el-input v-model="form.vehicleTypeName" placeholder="请输入转运车名称" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="vehicleTypeName" label="车辆类型名称" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span effect="dark" v-if="scope.row.status===0">{{ '新增' }}</span>
              <span effect="dark" type="success" v-else-if="scope.row.status===1">{{ '正常' }}</span>
              <span type="info" effect="dark" v-else>{{ '停用' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="150px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog
        :visible.sync="orderDialogFormVisible"
        class="order-dialog"
        @close="editDialogClose()"
      >
        <span slot="title" class="dialog-Title">转运车类型详情</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="addFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车辆类型名称" prop="vehicleTypeName">
              <el-input
                v-model="selectForm.vehicleTypeName"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="selectForm.status"
                placeholder="请选择状态"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="selectForm.remark"
                autocomplete="off"
                :disabled="isDisabled"
                clearable
              ></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加转运车类型</span>
        <el-form :model="addForm" inline ref="addForm" :rules="addFormRules" class="addForm">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="车辆类型名称" prop="vehicleTypeName">
              <el-input v-model="addForm.vehicleTypeName" autocomplete="off" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="状态" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable clearable>
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'cartype',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { vehicleTypeName: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        vehicleTypeName: [
          { required: true, message: '请输入车辆类型名称', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '新增', status: 0 }, { label: '正常', status: 1 }, { label: '停用', status: 2 }]
    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有转运车
    async getAllCar () {
      console.log(this.paging.pageSize, this.paging.PageNo)
      const { data: res } = await this.$http.post('/pmService/tInfoVehicleType/getTInfoVehicleTypeList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.pageNo
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取转运车列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.vehicleTypeName === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.pageNo = 1
        const { data: res } = await this.$http.post('/pmService/tInfoVehicleType/getTInfoVehicleTypeList', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.pageNo,
          vehicleTypeName: this.form.vehicleTypeName
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = +res.data.total
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (pageNo) {
      this.paging.pageNo = pageNo
      this.getAllCar()
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      console.log(v)
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击查看详情按钮触发
    look (v) {
      console.log(v)
      this.isDisabled = true
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除：${e.vehicleTypeName}`, '删除转运车', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data } = await this.$http.post('/pmService/tInfoVehicleType/deleteTInfoVehicleType', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (data.statusCode !== '200') return this.$message.error('删除转运车失败')
          // 成功提示
          this.$message.success('删除转运车成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    async sure () {
      console.log(JSON.parse(JSON.stringify(this.selectForm)))
      const that = this
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return that.orderDialogFormVisible = false } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // 进行编辑操作
          const { data: res } = await this.$http.post('/pmService/tInfoVehicleType/updateTInfoVehicleType', JSON.parse(JSON.stringify(this.selectForm)))

          console.log(res)
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        this.addForm.status = '1'
        // 调接口，发送添加客户请求
        const { data } = await this.$http.post('/pmService/tInfoVehicleType/addTInfoVehicleType', JSON.parse(JSON.stringify(this.addForm)))
        // 判断请求是否成功
        if (data.statusCode !== '200') return this.$message.error('添加转运车失败')
        // 成功提示
        this.$message.success('添加转运车成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    },
    editDialogClose () {
      this.$refs.selectForm.resetFields()
    }
  },
  mounted () {
    // var that = this;
    // this.$api.get('/pmService/tInfoVehicleType/selecteTInfoVehicleTypeService', { pageNo: 0, pageSize: 20 },
    //   s => {
    //     console.log("cha" + s)
    //   }, f => {
    //     console.log(f.data)
    //     this.rightsList = f.data
    //   })

  }
}
</script>
<style lang="less" scoped>
.cartype {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
